<template>
  <div>
    <!-- mobile -->
    <div
      class="fixed top-0 left-0 z-50 w-full bg-gray-900 rounded-b-lg shadow-md lg:hidden"
    >
      <a href="tel:+60326984257" id="cta-call-top">
        <h1 class="py-3 text-lg font-bold text-center text-white">
          Call Us 03-26984257
        </h1>
      </a>
    </div>

    <!-- main banner -->
    <div class="section-header">
      <div class="header-bg"></div>
      <!-- content -->
      <div class="container pt-20 lg:pt-0 header-custom tagline">
        <div class="header-intro">
          <img src="/images/main-logo.png" alt="" class="w-64 pb-5" />
          <h1 class="pb-2 text-3xl font-bold text-gray-900 md:text-5xl">
            The #1 Carpet <br class="lg:hidden" />
            Wholesaler <br class="lg:hidden" />
            in Malaysia
          </h1>
          <p class="pt-4 text-base text-gray-600 md:text-lg">
            We Provide Free <br />
            Professional <br />
            Consultation & Installation
          </p>
          <!-- enquiry-btn -->
          <div class="pt-6 pb-4">
            <div class="text-white md:flex">
              <div
                class="w-4/5 py-2 my-2 text-center bg-gray-600 rounded-lg hover:bg-gray-800 md:w-64 md:mx-1"
              >
                <a
                  href="https://wa.me/60125008471"
                  class="font-semibold texl-base lg:text-xl text-black-300"
                  >Get Quotation Now</a
                >
              </div>
              <div
                class="w-4/5 py-2 my-2 text-center bg-gray-600 rounded-lg hover:bg-gray-800 md:w-56 md:mx-1"
              >
                <a
                  href="https://wa.me/60125008471"
                  class="font-semibold texl-base lg:text-xl text-black-300"
                >
                  <i class="pr-1 text-lg fab fa-whatsapp"></i> 012-5008471
                </a>
              </div>
              <div
                class="w-4/5 py-2 my-2 text-center text-gray-600 border-2 border-gray-600 rounded-lg hover:text-white hover:bg-gray-800 md:w-56 md:mx-1"
              >
                <a
                  href="http://udanicarpets.com.my/"
                  target="_blank"
                  class="font-semibold texl-base lg:text-xl text-black-300"
                  >Website</a
                >
              </div>
            </div>
          </div>
          <!-- end enquiry-btn -->

          <div class="lg:block">
            <h1 class="text-xl font-semibold text-gray-600">About Us</h1>
            <h1 class="pb-3 text-2xl font-bold text-gray-900">
              Udani Carpet Sdn Bhd
            </h1>
            <div class="bg-gray-900 h-0.5 w-10"></div>

            <div class="pt-6 text-sm font-light text-gray-700">
              <p class="pb-2">
                Udani Carpets Sdn Bhd formally known as Udani Carpets &
                Furnishing, one of the most exclusive carpet’s showrooms in
                Malaysia was established in 1977 by Mr. RB Udani and Mr. Ashok
                Udani together with their new joined in third generation Mr.
                Dhruv Udani.
              </p>
              <p>
                Udani Carpets offers high quality imported carpets and rugs,
                promise to deliver highest customer’s satisfaction and fulfill
                high standard flooring arts. The company’s continuous growth
                strategies over the year have transformed it to an employee of
                over 20 workers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- about us -->

    <!-- <div class="pb-10 bg-gray-100 lg:hidden">
      <div class="container lg:relative md:max-w-md md:mx-auto">
        <h1 class="text-xl font-semibold text-gray-600">About Us</h1>
        <h1 class="pb-3 text-2xl font-bold text-gray-900">
          Udani Carpet Sdn Bhd
        </h1>
        <div class="bg-gray-900 h-0.5 w-10"></div>

        <div class="pt-6 text-sm font-light text-gray-700">
          <p class="pb-2">
            Udani Carpets Sdn Bhd formally known as Udani Carpets & Furnishing,
            one of the most exclusive carpet’s showrooms in Malaysia was
            established in 1977 by Mr. RB Udani and Mr. Ashok Udani together
            with their new joined in third generation Mr. Dhruv Udani.
          </p>
          <p>
            Udani Carpets offers high quality imported carpets and rugs, promise
            to deliver highest customer’s satisfaction and fulfill high standard
            flooring arts. The company’s continuous growth strategies over the
            year have transformed it to an employee of over 20 workers.
          </p>
        </div>
      </div>
    </div> -->

    <!-- 2nd section -->
    <div class="pt-10 md:relative md:z-10 choose-custom lg:pt-32 xl:pt-48">
      <!-- why choose us -->
      <div class="container md:max-w-md lg:max-w-3xl md:mx-auto">
        <h1 class="pb-3 text-2xl font-bold text-gray-900 uppercase">
          Why Udani Is Always Your First Choice?
        </h1>
        <div class="pt-6 text-sm font-light text-gray-700">
          <p>
            Udani Carpets offers high quality imported carpets and rugs, promise
            to deliver highest customer’s satisfaction and fulfill high standard
            flooring arts. The company’s continuous growth strategies over the
            year have transformed it to an employee of over 20 workers.
          </p>
        </div>
        <div class="flex flex-wrap pt-5">
          <div
            v-for="(item, i) in choose"
            :key="i"
            class="w-1/2 p-2 text-center lg:w-1/4"
          >
            <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
            <h1 class="pt-3 text-base font-bold leading-tight text-gray-800">
              {{ item.alt }}
            </h1>
          </div>
        </div>
      </div>

      <!-- product section -->
      <MainProduct />
    </div>

    <!-- 3rd section -->
    <div
      class="relative w-full bg-white bg-right-bottom bg-no-repeat bg-cover enquire-section"
    >
      <div
        class="absolute top-0 left-0 w-full h-full bg-auto testi-custom"
        style="background-image: url('/images/bg-choose.jpg')"
      ></div>

      <!-- content -->
      <div class="container relative">
        <div class="md:max-w-md lg:max-w-full lg:flex lg:items-center md:mx-auto">
          <div class="text-white lg:w-1/2">
            <h1 class="text-2xl">MAKE A QUICK ENQUIRE FOR YOUR CARPET!</h1>
            <div class="pt-3 text-sm">
              <p class="pb-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                euismod est felis, non maximus nisi cursus vitae. Aenean
                pharetra dignissim felis, vitae suscipit purus elementum at.
              </p>
              <p>
                Phasellus faucibus turpis a nisl feugiat dignissim. Quisque ut
                rhoncus arcu. In at volutpat nisi, at interdum magna. Curabitur
                ultrices interdum ante ac sagittis. Ut ex risus, dapibus non
                feugiat eu, finibus non sem.
              </p>
            </div>
          </div>

          <!-- enquire -->
          <div class="w-4/5 pt-6 mx-auto lg:pt-0 lg:w-1/2">
            <div class="p-4 bg-red-900">
              <h1 class="py-5 text-xl font-bold text-center text-white">
                <a href="tel:+60326984257"> Call Us 03-26984257 </a>
              </h1>
              <!-- feedback.activamedia.com.sg script begins here --><iframe
                allowTransparency="true"
                style="min-height: 530px; height: inherit; overflow: auto"
                width="100%"
                id="contactform123"
                name="contactform123"
                marginwidth="0"
                marginheight="0"
                frameborder="0"
                src="https://feedback.activamedia.com.sg/my-contact-form-5516767.html"
                ><p>
                  Your browser does not support iframes. The contact form cannot
                  be displayed. Please use another contact method (phone, fax
                  etc)
                </p></iframe
              ><!-- feedback.activamedia.com.sg script ends here -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Testimonial -->
    <div class="py-12">
      <div class="container md:max-w-md lg:max-w-3xl md:mx-auto">
        <div class="max-w-sm mx-auto text-center md:max-w-full">
          <h1
            class="pb-8 text-2xl font-bold text-center text-gray-900 uppercase"
          >
            Our Testimonials
          </h1>
          <carousel
            :autoplay="true"
            :responsive="{ 0: { items: 1, nav: false } }"
          >
            <div>
              <p class="text-gray-600 lg:text-lg">
                I have been ordered carpets from Udani for 20 years, they always
                provide me the suitable one based on my need. The quality is
                superb and price is reasonable.
              </p>
              <h1 class="pt-4 text-lg font-semibold text-gray-800 lg:text-xl">
                - Kris -
              </h1>
            </div>
            <div>
              <p class="text-gray-600 lg:text-lg">
                My carpets supplier for my 3 offices in KL, best service ever!
                Thumbs Up!
              </p>
              <h1 class="pt-4 text-lg font-semibold text-gray-800 lg:text-xl">
                - Hafiz -
              </h1>
            </div>
            <div>
              <p class="text-gray-600 lg:text-lg">
                The quality of carpets is so nice, love it! Been survey for few
                carpet suppliers, only Udani give me the best service and price.
              </p>
              <h1 class="pt-4 text-lg font-semibold text-gray-800 lg:text-xl">
                - Kumaran -
              </h1>
            </div>
            <div>
              <p class="text-gray-600 lg:text-lg">
                Udani carpet offer the best price among the few suppliers I
                survey. Also, the staff is very friendly and helpful. Good
                quality and Highly recommended!
              </p>
              <h1 class="pt-4 text-lg font-semibold text-gray-800 lg:text-xl">
                - Nadia -
              </h1>
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10 bg-gray-50">
      <div class="container md:max-w-md lg:container md:mx-auto">
        <div class="lg:flex lg:items-center">
        <div class="lg:w-1/2 lg:order-2">
        <h1 class="pb-3 text-2xl font-bold text-center text-gray-900 uppercase lg:hidden">
          About Udani Carpets
        </h1>
        <div class="pt-6">
          <img src="/images/about.jpg" alt="" class="" />
          <div class="pt-3">
            <h1 class="text-lg font-bold text-gray-800">
              Udani Carpets Sdn Bhd (HQ)
            </h1>
            <p class="pt-2 text-sm leading-tight">
              393, Jalan Tuanku Abdul Rahman Kuala Lumpur, Kuala Lumpur, 50100
            </p>
            <p class="pt-2 text-sm">
              Office : <a href="tel:+60326984257">03-26984257</a>
            </p>
          </div>
          <div class="pt-3">
            <h1 class="text-lg font-bold text-gray-800">
              Udani Carpets Sdn Bhd (Warehouse)
            </h1>
            <p class="pt-2 text-sm leading-tight">
              Jalan Mekanikal 1, Kawasan Perindustrian Nilai 3, 71800 Nilai,
              Negeri Sembilan, Malaysia
            </p>
            <p class="pt-2 text-sm">
              Office : <a href="tel:+60326984257">03-26984257</a>
            </p>
            <p class="pt-2 text-sm">
              Mobile : <a href="tel:+60125008471">012-5008471</a>
            </p>
          </div>
        </div>
        </div>

        <div class="pt-6 lg:pt-0 lg:pr-5 lg:w-1/2">
          <h1
            class="pb-6 text-2xl font-bold text-center text-gray-900 uppercase lg:text-left"
          >
            Award Winning Interior Design
          </h1>
          <div class="text-sm text-center text-gray-600 lg:text-left">
            <p class="pb-2">
              Udani Carpets Sdn Bhd formally known as Udani Carpets &
              Furnishing, one of the most exclusive carpet’s showrooms in
              Malaysia was established in 1977 by Mr. RB Udani and Mr. Ashok
              Udani together with their new joined in third generation Mr. Dhruv
              Udani.
            </p>
            <p>
              Udani Carpets offers high quality imported carpets and rugs,
              promise to deliver highest customer’s satisfaction and fulfill
              high standard flooring arts. The company’s continuous growth
              strategies over the year have transformed it to an employee of
              over 20 workers.
            </p>
          </div>
        </div>
        </div>
      </div>
    </div>

    <!-- location -->
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7967.482837403726!2d101.698645!3d3.162695!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x145bc9d33b77ab6b!2sUdani%20Carpets%20Sdn.%20Bhd.!5e0!3m2!1sen!2smy!4v1640222999319!5m2!1sen!2smy"
        width="100%"
        height="250"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
    <!-- Copyright -->
    <div class="py-3 bg-gray-800">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainProduct from "@/components/MainProduct.vue";
import carousel from "vue-owl-carousel";

export default {
  name: "Home",
  components: {
    MainProduct,
    carousel,
  },
  data() {
    return {
      choose: [
        { image: "/images/icon-1.png", alt: "30 Days Warranty" },
        { image: "/images/icon-2.png", alt: "Customer Satisfaction Guarantee" },
        { image: "/images/icon-3.png", alt: "Lowest Price Guarantee" },
        { image: "/images/icon-4.png", alt: "Quality Assurance" },
      ],
    };
  },
};
</script>

<style>
.tagline {
  position: relative;
  z-index: 2;
}
.header-bg {
  background: url("/images/main-mobile.jpg") center top no-repeat;
  background-position: 60% 10%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.section-header {
  background-color: #f3f4f6;
  height: auto;
  margin-top: 10px;
  z-index: 1;
}
.h-custom {
  height: 400px;
}
/* .bg-custom {
  background-image: url("/images/main-mobile.jpg");
  background-position-x: 63%;
} */
.choose-custom {
  background-image: url("/images/features.jpg");
  background-color: #f3f4f6;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  width: 100%;
  height: auto;
  /* padding-top: 40px; */
}

.enquire-section {
  height: auto;
  padding-top: 450px;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  /* .bg-custom {
    background-position-x: 74%;
    background-position-y: 45px;
  } */
  .header-custom{
    width: 445px !important;
  }
}

@media (min-width: 1024px) {
  .header-bg {
    background-image: url("/images/main-banner.jpg");
    background-position: center bottom;
  }
  .header-intro {
    width: 596px;
    margin: 75px 0 0 335px;
  }
  .header-custom{
   width: auto !important;
  }
  .enquire-section{
    padding: 480px 0 160px;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: cover;
    width: 100%;
    background-color: #fff;
    height: auto;
    position: relative;
  }
  .testi-custom{
    background-position-x: 40%;
  }
}

@media(min-width: 1280px){
  .header-intro {
    margin: 75px 0 0 470px;
}
}
</style>
