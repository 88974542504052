<template>
  <div class="container pt-10 md:max-w-md md:mx-auto lg:container">
    <h1 class="pb-3 text-2xl font-bold text-center text-gray-900 uppercase md:text-left">Our Products</h1>
    <p class="text-sm font-light text-gray-700">
      We have a Wide Variety of Designs Of Functional and International
      Standards Carpet Solutions
    </p>

    <!-- content -->
    <!-- 1 row -->
    <!-- <div class="pt-10 lg:flex">
      <div class="lg:w-1/2 lg:p-2">
        <div
          class="w-full h-full px-5 py-12 text-center bg-gray-800 lg:flex lg:flex-wrap lg:justify-center lg:items-center lg:p-12 lg:text-left"
        >
          <div class="text-wrap">
            <h1 class="text-3xl font-bold text-white lg:text-4xl">
              Residential Carpet
            </h1>
            <p
              class="pt-6 text-sm font-light leading-tight text-left text-white"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              euismod est felis, non maximus nisi cursus vitae. Aenean pharetra
              dignissim felis, vitae suscipit purus elementum at. Phasellus
              faucibus turpis a nisl feugiat dignissim. Quisque ut rhoncus arcu.
            </p>
          </div>
        </div>
      </div>
      <div class="flex-wrap pt-3 lg:w-1/2 lg:pt-0 lg:flex lg:">
        <div class="hidden lg:block lg:w-1/2 lg:p-2">
          <img
            src="/images/resident-1.jpg"
            alt=""
            class="lg:w-full lg:h-full"
          />
        </div>
        <div class="hidden lg:block lg:p-2 lg:w-1/2">
          <div
            class="flex items-center justify-center w-full h-full bg-gray-800"
          >
            <h1 class="text-2xl font-bold text-center text-white">
              House Carpet
            </h1>
          </div>
        </div>
        <div class="lg:w-full lg:p-2">
          <img src="/images/resident-2.jpg" alt="" class="w-full" />
        </div>
      </div>
    </div> -->

    <!-- 2 row -->
    <div class="pt-10 lg:flex">
      <div class="lg:w-1/2 lg:flex">
        <div class="lg:w-1/2 lg:p-2">
          <div
            class="w-full h-full px-5 py-12 bg-gray-800 lg:flex lg:items-center lg:justify-center"
          >
            <h1 class="text-2xl font-bold text-center text-white">
              Office Carpets
            </h1>
          </div>
        </div>
        <div class="hidden lg:w-1/2 lg:p-2 lg:block">
          <img src="/images/office-1.jpg" alt="" class="w-full h-full" />
        </div>
      </div>
      <div class="pt-3 lg:w-1/2 lg:p-2">
        <img src="/images/office-2.jpg" alt="" class="w-full mx-auto" />
      </div>
    </div>

    <!-- 3 row -->
    <div class="pt-3 lg:pt-0 lg:flex">
      <div class="lg:w-1/2 lg:flex">
        <div class="hidden lg:w-1/2 lg:p-2 lg:block">
          <img src="/images/exhibition-1.jpg" alt="" class="w-full" />
        </div>
        <div class="lg:w-1/2 lg:p-2">
          <div
            class="px-5 py-12 bg-gray-800 lg:flex lg:justify-center lg:items-center lg:h-1/2"
          >
            <h1 class="text-2xl font-bold text-center text-white">
              Exhibition & <br class="hidden lg:block" />
              Event Carpet
            </h1>
          </div>
          <div class="pt-3 lg:h-1/2">
            <img
              src="/images/exhibition-2.jpg"
              alt=""
              class="hidden w-full h-full lg:block"
            />
            <img
              src="/images/exhibition-2-1.jpg"
              alt=""
              class="w-full h-full lg:hidden"
            />
          </div>
        </div>
      </div>
      <div class="hidden lg:w-1/2 lg:p-2 lg:block">
        <img src="/images/exhibition-3.jpg" alt="" class="w-full h-full" />
      </div>
    </div>

    <!-- 4 row -->
    <div class="pt-3 lg:flex lg:pt-0">
      <div class="hidden lg:p-2 lg:w-1/4 lg:block">
        <img src="/images/masjid-2.jpg" alt="" class="w-full" />
      </div>

      <div class="lg:w-3/4">
        <div class="lg:flex lg:h-1/2">
          <div class="lg:w-1/3 lg:p-2 lg:order-2">
            <div
              class="px-5 py-12 bg-gray-800 lg:flex lg:justify-center lg:items-center lg:w-full lg:h-full"
            >
              <h1 class="text-2xl font-bold text-center text-white">
                Masjid & <br class="hidden lg:block" />
                Surau Carpet
              </h1>
            </div>
          </div>
          <div class="pt-3 lg:w-2/3 lg:p-2 lg:order-1">
            <img
              src="/images/masjid-1.jpg"
              alt=""
              class="md:w-full lg:h-full"
            />
          </div>
        </div>

        <div class="pt-3 lg:pt-0 lg:flex lg:h-1/2">
          <div class="lg:w-1/3 lg:p-2">
            <div
              class="px-5 py-12 bg-gray-800 lg:w-full lg:h-full lg:flex lg:justify-center lg:items-center"
            >
              <h1 class="text-2xl font-bold text-center text-white">
                Artificial Grass Carpet
              </h1>
            </div>
          </div>
          <div class="pt-3 lg:p-2 lg:w-2/3">
            <img src="/images/grass-1.jpg" alt="" class="md:w-full lg:h-full" />
          </div>
        </div>
      </div>
    </div>

    <!-- 5 row -->
    <!-- <div class="pt-3 lg:flex lg:pt-0">
      <div class="lg:w-1/2 lg:flex lg:flex-wrap">
        <div class="lg:w-1/2 lg:p-2">
          <div
            class="px-5 py-12 bg-gray-800 lg:flex lg:justify-center lg:items-center lg:w-full lg:h-full"
          >
            <h1 class="text-2xl font-bold text-center text-white">
              SPC and Vinyl flooring
            </h1>
          </div>
        </div>
        <div class="hidden lg:w-1/2 lg:p-2 lg:block">
          <img src="/images/spc-2.jpg" alt="" class="lg:w-full lg:h-full" />
        </div>
        <div class="pt-3 lg:p-2 lg:w-full">
          <img src="/images/spc-1.jpg" alt="" class="md:w-full" />
        </div>
      </div>
      <div class="hidden lg:w-1/2 lg:block lg:p-2">
        <img src="/images/spc-3.jpg" alt="" class="lg:w-full lg:h-full" />
      </div>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>